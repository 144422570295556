<template>
  <div class="flex-col page">
    <div class="flex-col header">
      <div class="flex-row group_2" @click="toBack">
        <img src="../../../assets/img/back.png" class="image_3" />
        <span class="text_1">返回</span>
      </div>
    </div>
    <div class="flex-col group_3">
      <div class="flex-col section_1">
        <div class="flex-col">
          <div class="justify-between">
            <span class="text_2">{{job_title}}</span>
            <div class="flex-row group_6" @click="like()">
              <img v-if="Ilike == '1'" src="../../../assets/jobimg/liked.png" class="image_4" />
              <img v-else src="../../../assets/jobimg/nolike.png" class="image_4" />
            </div>
          </div>
          <div class="justify-between group_7">
            <span class="text_3">
              <p v-if="type =='2'">{{monthly_pay1}}-{{monthly_pay2}} 元/月</p>
            </span>
            <span class="text_4" @click="like()">{{Ilike == '1' ? '取消收藏' : '添加收藏'}}</span>
          </div>
        </div>
        <div class="flex-col group_8">
          <div class="flex-row group_9" v-if="type =='2'">
            <div class="flex-row">
              <img src="../../../assets/jobimg/head_count.png" class="image_7" />
              <span class="text_6">招{{head_count}}人</span>
            </div>
            <div class="flex-row group_11">
              <img src="../../../assets/jobimg/work_year.png" class="image_8" />
              <span class="text_6">{{work_years}}年以下</span>
            </div>
            <div class="flex-row group_12">
              <img src="../../../assets/jobimg/education.png" class="image_8" />
              <span class="text_6">{{education_arr[education]}}</span>
            </div>
          </div>
          <div class="flex-row section_2" v-if="type =='2'">
            <img src="../../../assets/jobimg/line.png" class="image_8" />
            <span class="text_8">福利:</span>
            <span class="text_9">{{welfare_treatment}}</span>
          </div>

          <div class="text_5"  v-if="type =='2'">招聘单位:{{job_unit}}</div>
          <div class="text_5"  v-if="type =='2'">工作城市:{{work_city}}</div>
          <div class="text_5"  v-if="type =='2'">工作地址:{{work_place}}</div>
          <div class="text_5"  v-if="type =='1'">想要工作城市:{{work_city}}</div>
          <div class="text_5"  v-if="type =='1'">想要工作地址:{{work_place}}</div>
          
          <img src="" class="image_12 image_13" />
          <div class="justify-between group_13">
            <div class="flex-row">
              <img src="../../../assets/logo.png" class="image_14" />
              <div class="flex-col group_15">
                <span class="text_10">{{hr_name}}</span>
                <div class="flex-row" style="margin-top: 5px;">
                  <!--               <img src="../../../assets/jobimg/dot.png" class="image_15" />
                  <span class="text_11">当前在线</span> -->
                </div>
              </div>
            </div>
            <div class="flex-row">
              <div class="flex-row section_3" @click="phone()">
                <a :href="'tel:' + tel"> </a>
                <img src="../../../assets/jobimg/tel.png" class="image_16" />
                <span class="text_12">电话</span>
              </div>
              <div class="flex-row section_4" @click="message()">
                <img src="../../../assets/jobimg/msg.png" class="image_16" />
                <span class="text_13">沟通</span>
              </div>
              <!--           <div class="flex-row section_4" @click="report()">
                <img src="../../../assets/jobimg/msg.png" class="image_16" />
                <span class="text_13">举报</span>
              </div> -->
            </div>
          </div>
          <img src="../../../assets/jobimg/line.png" class="image_12" />
        </div>
        <div class="flex-col group_18">
          <span class="text_14"> {{type == '2' ? '任职要求:' : '自我介绍:'}}</span>
          {{job_desc}}
          <!-- <span class="text_15">
            1. 该职位由【东软集团股份有限公司】发布，报名培训后可推荐就业该职位。20-30岁（大专及以上学历）不招聘假期工！
          </span>
          <span class="text_16">2. 无经验，零基础，其他行业想从事本岗位，本公司可提供老带新机会（可零基础实习）！</span> -->
        </div>

        <div class="m-report-qm" style="bottom: 62px; position: fixed;">
          <a @click="report();" class="clearfix">
            <i></i>举报
          </a>
          <div class="txt">
            <h3>如遇假招聘，点击举报</h3>
            <p>请小心网络兼职刷单诈骗、未入职就收取培训费、押金及体检费等诈骗</p>
          </div>
        </div>
      </div>
      <div class="flex-col items-center section_5" v-if="owner == 1">
        <div class="flex-row button" @click="deal_done()">
          <img src="../../../assets/jobimg/report.png" class="image_4 image_20" />
          <span class="text_17">{{type == '2' ? '我已招聘成功' : '我已求职成功'}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from "js-cookie";
  import {
    getQueryVariable
  } from "@/utils/request";
  import wx from 'weixin-js-sdk';
  import {
    Toast
  } from "vant";
  export default {
    components: {},
    data() {
      return {
        Ilike: '0', //默认不关注
        jobid: '',
        type: '',
        job_title: '',
        monthly_pay1: '',
        monthly_pay2: '',
        welfare_treatment: '', //职务标签
        jobs_range: '',
        head_count: '',
        job_desc: '',
        education: '',
        education_arr: ['', '不限制', '初中及以上', '高中及以上', '大专及以上', '大学及以上'],
        jobs_range_arr: ['', '全职', '兼职'],
        work_years: '',
        work_city: '',
        work_place: '',
        tel: '',
        edit_time: '',
        num_applicants: '',
        num_hits: '',
        job_unit: '',
        hr_name: '',
        owner: 0, //是不是帖子的主人,如果是,出现完成按钮
      };
    },
    mounted() {
      //判断一下，cookie如果丢失了，那么就重新登录
      if (Cookies.get("kh") == "" || Cookies.get("kh") == null) {
        //如果获取用户信息失败那就直接跳到登录
        this.$router.push("login");
        return;
      }
      this.getJobDetails();
      this.getWxConfig(); //加载js
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });


        } else {
          Toast(result.msg);
        }
      },
      report() {
        //改为调用企业微信客服
        window.location.href = "https://work.weixin.qq.com/kfid/kfc87b5402ee83a9aec";
        //联系客服 举报
        //this.$router.push("chat?userCode=service");
      },

      toBack() {
        //后退
        this.$router.back();
      },
      async getJobDetails() {
        //查询招聘
        let url = location.href;
        let id = decodeURI(getQueryVariable(url, "id"));
        var that = this;
        var uri = this.$global.domain + "/tools/post_job.ashx";
        var param = {
          act: "getJobsDetails",
          id: id, //本次查询的id
          user: Cookies.get("kh"),
          sitenum: '18777777777',
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          let data = result.data;
          //
          that.jobid = data[0][0];
          that.type = data[0][1];
          that.job_title = data[0][2];
          that.monthly_pay1 = data[0][3];
          that.monthly_pay2 = data[0][4];
          that.welfare_treatment = data[0][5]; //职务标签
          that.jobs_range = data[0][6];
          that.head_count = data[0][7];
          that.job_desc = data[0][8];
          that.education = data[0][9];
          that.work_years = data[0][10];
          that.work_city = data[0][11];
          that.work_place = data[0][12];
          that.tel = data[0][13];
          that.edit_time = data[0][14];
          that.num_applicants = data[0][15];
          that.num_hits = data[0][16];
          that.job_unit = data[0][17];
          that.hr_name = data[0][18];
          that.issuer_code = data[0][20]; //招聘所有者账号

          if (that.issuer_code == "1") {
            that.Ilike = "1";
          }


          if (that.issuer_code == Cookies.get("kh")) {
            that.owner = 1;
          }


          // console.log ("=========>" +result.issuer_code);
          // console.log ("=========>"+Cookies.get("kh"));
          // console.log ("=========>" +  that.owner);
        } else {

          //this.$router.push("login");
        }
      },
      async message(item) {
        //凡是有人点了消息,那么就申请人数就+1

        let uri = this.$global.domain + "/tools/post_job.ashx";
        let param = {
          act: "applicant",
          user: Cookies.get("kh"),
          id: this.jobid, //
          sitenum: "18777777777",
          token: Cookies.get("token")
        };
        let result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          //消息中心
          this.$router.push({
            path: '/chat',
            query: {
              'userCode': this.issuer_code
            }
          });
        }

      },
      phone(item) {
        //打电话
        window.location.href = 'tel://' + this.tel;
      },
      deal_done(item) {
        //成交
        this.$router.push({
          path: '/jos_deal_done',
          query: {
            'id': this.jobid
          }
        });
      },
      async like() {
        //喜欢 关注
        let that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "collection2021",
          user: Cookies.get("kh"),
          code: this.jobid, //
          type: "4", //2是收藏会员，1是收藏商品  3是房产  4是招聘
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          //需要切换收藏图片状态
          if (that.Ilike == 0) {
            that.Ilike = "1";
          } else {
            that.Ilike = "0";
          }
        } else {}
      },



    },

  };
</script>

<style scoped lang="css">
  .image_12 {
    width: 19.44rem;
    height: 0.063rem;
  }

  .image_4 {
    margin-right: 10px;
    width: 1.25rem;
    height: 1.25rem;
  }

  .image_8 {
    margin: 0.13rem 0 0.19rem;
    flex-shrink: 0;
    width: 0.75rem;
    height: 0.75rem;
  }

  .image_16 {
    margin-bottom: 0.13rem;
    flex-shrink: 0;
    width: 0.88rem;
    height: 0.88rem;
  }

  .page {
    background-color: #f8f8f8ff;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  .header {
    padding: 0.63rem 0.88rem 0.75rem 1.16rem;
    background-color: #ffffff;
    height: 2.6rem;
  }

  .group_3 {
    padding-top: 1rem;
    flex: 1 1 auto;
    overflow-y: auto;
  }

  .group {
    padding-left: 0.97rem;
  }

  .group_2 {
    /*    margin-top: 1.38rem; */
  }

  .section_1 {
    margin: 0 1rem;
    padding: 1.38rem 0.94rem 16.25rem 1rem;
    background-color: #ffffff;
    border-radius: 0.5rem;
  }

  .section_5 {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: 1.13rem;
    padding: 1.25rem 0 1.5rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 0.38rem 0.38rem #0000000a;
  }

  .text {
    color: #232323;
    font-size: 0.88rem;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    line-height: 1.25rem;
    text-transform: uppercase;
  }

  .group_1 {
    margin: 0.22rem 0 0.25rem;
  }

  .image_3 {
    margin: 0.094rem 0 0.22rem;
    width: 0.63rem;
    height: 1.06rem;
  }

  .text_1 {
    margin-left: 1.09rem;
    color: #292929;
    font-size: 1rem;
    font-family: 'PingFangSC-Regular';
    line-height: 1.38rem;
  }

  .group_8 {
    margin-top: 1.13rem;
  }

  .group_18 {
    margin-top: 1.34rem;
  }

  .button {
    padding: 0.44rem 0 0.31rem;
    background-color: #36c1bc;
    border-radius: 0.13rem;
    width: 19.44rem;
    border: solid 0.063rem #36c1bc;
  }

  .image {
    margin-top: 0.094rem;
    width: 1.13rem;
    height: 0.69rem;
  }

  .image_1 {
    margin-left: 0.25rem;
    width: 1rem;
    height: 0.75rem;
  }

  .image_2 {
    margin-left: 0.25rem;
    width: 1.56rem;
    height: 0.72rem;
  }

  .group_7 {
    margin-top: 0.38rem;
  }

  .group_9 {
    padding-bottom: 0.81rem;
  }

  .section_2 {
    padding: 0.25rem 0.5rem 0.19rem;
    background-color: #36c1bc1a;
    border-radius: 0.13rem;
  }

  .image_13 {
    margin-top: 0.75rem;
  }

  .group_13 {
    padding: 1.28rem 0 1.19rem;
  }

  .text_14 {
    align-self: flex-start;
    color: #292929;
    font-size: 0.88rem;
    font-family: 'PingFangSC-Semibold';
    font-weight: 600;
    line-height: 1.25rem;
  }

  .text_15 {
    margin-top: 0.69rem;
    color: #616161;
    font-size: 0.63rem;
    font-family: 'PingFangSC-Regular';
    line-height: 1rem;
    text-align: justify;
  }

  .text_16 {
    margin-top: 1.13rem;
    color: #616161;
    font-size: 0.63rem;
    font-family: 'PingFangSC-Regular';
    line-height: 1rem;
    text-align: justify;
  }

  .image_20 {
    margin-left: 5.2rem;
    margin-bottom: 0.13rem;
  }

  .text_17 {
    margin-left: 0.25rem;
    color: #ffffff;
    font-size: 1rem;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    line-height: 1.38rem;
  }

  .text_2 {
    color: #292929;
    font-size: 1rem;
    font-family: 'PingFangSC-Semibold';
    font-weight: 600;
    line-height: 1.38rem;
  }

  .group_6 {
    margin-top: 0.13rem;
  }

  .text_3 {
    color: #f6ad4f;
    font-size: 1rem;
    font-family: 'PingFangSC-Semibold';
    font-weight: 600;
    line-height: 1.38rem;
  }

  .text_4 {
    margin-top: 0.44rem;
    color: #292929;
    font-size: 0.63rem;
    font-family: 'PingFangSC-Regular';
    line-height: 0.88rem;
  }

  .group_11 {
    margin-left: 2.5rem;
  }

  .group_12 {
    margin-left: 2.5rem;
  }

  .text_8 {
    margin-left: 0.5rem;
    color: #292929;
    font-size: 0.75rem;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    line-height: 1.06rem;
  }

  .text_9 {
    margin-left: 1.13rem;
    color: #292929;
    font-size: 0.75rem;
    font-family: 'PingFangSC-Regular';
    line-height: 1.06rem;
    width: 85%;
    overflow: hidden;
  }

  .image_6 {
    margin-left: 0.63rem;
  }

  .image_7 {
    margin-bottom: 0.13rem;
    flex-shrink: 0;
    width: 0.75rem;
    height: 0.88rem;

  }


  .text_5 {
    margin-left: 0.38rem;
    color: #616161;
    font-size: 0.75rem;
    font-family: 'PingFangSC-Regular';
    line-height: 1.06rem;
    margin-top: 0.5rem;
  }

  .text_6 {
    margin-left: 0.38rem;
    color: #616161;
    font-size: 0.75rem;
    font-family: 'PingFangSC-Regular';
    line-height: 1.06rem;
  }

  .text_7 {
    margin-left: 0.38rem;
    color: #616161;
    font-size: 0.75rem;
    font-family: 'PingFangSC-Regular';
    line-height: 1.06rem;
  }

  .image_14 {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
  }

  .group_15 {
    margin-left: 0.38rem;
  }

  .section_3 {
    padding: 0.19rem 0.44rem 0.13rem;
    border-radius: 0.25rem;
    height: 1.5rem;
    border: solid 0.063rem #36c1bc;
  }

  .section_4 {
    margin-left: 1.25rem;
    padding: 0.25rem 0.5rem 0.19rem;
    background-color: #36c1bc;
    border-radius: 0.25rem;
    height: 1.5rem;
  }

  .text_10 {
    color: #616161;
    font-size: 0.63rem;
    font-family: 'PingFangSC-Regular';
    line-height: 0.88rem;
    letter-spacing: -0.063rem;
  }

  .text_12 {
    margin-left: 0.38rem;
    margin-right: 0.13rem;
    color: #36c1bc;
    font-size: 0.75rem;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    line-height: 1.06rem;
  }

  .text_13 {
    margin-left: 0.38rem;
    margin-right: 0.13rem;
    color: #ffffff;
    font-size: 0.75rem;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    line-height: 1.06rem;
  }

  .image_15 {
    margin: 0.19rem 0;
    border-radius: 50%;
    width: 0.31rem;
    height: 0.31rem;
  }

  .text_11 {
    margin-left: 0.31rem;
    margin-right: 1.69rem;
    color: #f6ad4f;
    font-size: 0.5rem;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    line-height: 0.69rem;
  }



  /************************************************************
** 请将全局样式拷贝到项目的全局 CSS 文件或者当前页面的顶部 **
** 否则页面将无法正常显示                                  **
************************************************************/

  html {
    font-size: 16px;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', 'Microsoft Yahei', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
    flex-shrink: 0;
  }

  #app {
    width: 100vw;
    height: 100vh;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
  }

  .justify-start {
    display: flex;
    justify-content: flex-start;
  }

  .justify-center {
    display: flex;
    justify-content: center;
  }

  .justify-end {
    display: flex;
    justify-content: flex-end;
  }

  .justify-evenly {
    display: flex;
    justify-content: space-evenly;
  }

  .justify-around {
    display: flex;
    justify-content: space-around;
  }

  .justify-between {
    display: flex;
    justify-content: space-between;
  }

  .items-start {
    display: flex;
    align-items: flex-start;
  }

  .items-center {
    display: flex;
    align-items: center;
  }

  .items-end {
    display: flex;
    align-items: flex-end;
  }

  .m-report-qm {
    overflow: hidden;
    background-color: #fff;
    margin-bottom: 2.667vw;
    padding: 2.667vw;
    border-top: 1px #eee solid;
    border-bottom: 1px #eee solid;
    bottom: 62px;
    position: fixed;
    left: 15px;
    width: 345px;
  }

  .m-report-qm a {

    display: block;
    width: 17.067vw;
    float: right;
    border-left: 1px #f5f5f5 solid;
    margin-left: 2.667vw;
    text-align: center;
    font-size: 3.2vw;
    color: #4db0fd;
    line-height: 3.733vw;
  }

  .m-report-qm .txt {
    overflow: hidden;
    font-size: 3.2vw;
    color: #999;
    line-height: 3.733vw;
  }

  .m-report-qm .txt h3 {
    font-size: 4vw;
    color: #fb9031;
    font-weight: 400;
    line-height: 4.8vw;
    margin-bottom: 2.4vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .m-report-qm a i {
    display: block;
    width: 8vw;
    height: 8vw;
    background-position: -88vw -15.6vw;
    margin: 0 auto;
  }
</style>
